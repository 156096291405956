import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Artist from "../components/artist"
import Painting from "../components/painting"
import loadable from "@loadable/component"

const MonetComponent = loadable(() => import("../components/monetComponent"))

const Monet = ({ data }) => {
  return (

    <Layout>
      <SEO
        title="Claude Monet"
        description="The life and paintings of Oscar Claude Monet."
        image="/logo.png"
        pathname="/picasso"

      />

      <Artist
        bubble2={{ "--bubble2": "#0574b5" }}
        name={"Claude Monet"}
        dates={"(1840-1926)"}
        descrip={"Claude Monet was one of the founders of Impressionism, an art movement started in the 1800’s.   \"Try to forget what objects you have before you - a tree, a house, a field, or whatever. Merely think, 'Here is a little square of blue, here an oblong of pink, here a streak of yellow, and paint it just as it looks to you, the exact color and shape, until it gives you your own impression of the scene before you.'\" ~ Claude Monet "}
      />

      <Painting
        leftPanel={{ backgroundColor: "#5898be" }}
        h1Title={"Impression, Sunrise"}
        sectionDescription={"The term 'Impressionism' was taken from the title of one of his paintings called 'Impression, Sunrise.'  A critic said his painting created only an impression of a sunrise.  Most art critics at the time did not like Monet’s work and instead preferred paintings that showed realistic details. "}
        paintingFixed={data.impressionsunrise.childImageSharp.fixed}
        alt={"Impression, Sunrise"}
        rtPanel={{ backgroundColor: "#ef9d76" }}
        // Mobile
        paintingFluid={data.impressionsunrisemobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#5898be" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#3a3122" }}
        h1Title={"Caricature of Léon Manchon"}
        sectionDescription={"Monet’s father wanted him to get into the family grocery business, but from a very young age he was determined to become an artist.  He went to art school and at age 15 started making money selling his charcoal drawings.  He signed them O. Monet since his first name is Oscar, not Claude."}
        paintingFixed={data.caricatureofleonmanchon.childImageSharp.fixed}
        alt={"Caricature of Léon Mancho "}
        rtPanel={{ backgroundColor: "#d0d0cf" }}
        // Mobile
        paintingFluid={data.caricatureofleonmanchonmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#3a3122" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#bd3934" }}
        h1Title={"Poppy Field"}
        sectionDescription={"He didn’t like the art being made at the time, so along with other artists in Paris he began to paint in a completely new Impressionist style."}
        paintingFixed={data.poppyfield.childImageSharp.fixed}
        alt={" "}
        rtPanel={{ backgroundColor: "#668da8" }}
        // Mobile
        paintingFluid={data.poppyfieldmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#bd3934" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#671605" }}
        h1Title={"Wheatstacks (End of Summer)"}
        sectionDescription={"He noticed how objects looked different depending on the time of day and became blurred at a distance.  He experimented with the effect of light, painted with rapid brush strokes and seldom blended colors together.  His style captured the “essence” of a scene versus trying to look like a photo."}
        paintingFixed={data.wheatstacksendofsummer.childImageSharp.fixed}
        alt={"Wheatstacks (End of Summer)"}
        rtPanel={{ backgroundColor: "#ab8631" }}
        // Mobile
        paintingFluid={data.wheatstacksendofsummermobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#671605" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#dcbe78" }}
        h1Title={"Women in the Garden"}
        sectionDescription={"His favorite model was his first wife, Camille.  She appears in over 30 of his paintings.  In the painting Women in the Garden, she posed for all the female figures that appear."}
        paintingFixed={data.womeninthegarden.childImageSharp.fixed}
        alt={"Women in the Garden"}
        rtPanel={{ backgroundColor: "#edcd81" }}
        // Mobile
        paintingFluid={data.womeninthegardenmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#dcbe78" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#2a4373" }}
        h1Title={"Camille and a Child in the Garden"}
        sectionDescription={"Shortly after the birth of their second child, Camille died at the age of 32.  Monet later remarried and his second wife, Alice Hoschedé, was supposedly so jealous of Camille that she made him destroy all letters, photos, and mementos of her.  Today only one photograph of Camille survives."}
        paintingFixed={data.camilleandachildinthegarden.childImageSharp.fixed}
        alt={"Camille and a Child in the Garden "}
        rtPanel={{ backgroundColor: "#87a04d" }}
        // Mobile
        paintingFluid={data.camilleandachildinthegardenmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#2a4373" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#5d1a34" }}
        h1Title={"San Giorgio Maggiore at Dusk "}
        sectionDescription={"Although Monet became one of the most beloved artists of all time, he was his harshest critic.  Monet destroyed as many as 500 of his own paintings, often with a knife.  He once said of himself, “My life has been nothing but a failure."}
        paintingFixed={data.sangiorgiomaggioreatdusk.childImageSharp.fixed}
        alt={"San Giorgio Maggiore at Dusk"}
        rtPanel={{ backgroundColor: "#eaa533" }}
        // Mobile
        paintingFluid={data.sangiorgiomaggioreatduskmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#5d1a34" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#493e67" }}
        h1Title={"The Houses of Parliament, Sunset"}
        sectionDescription={"In 1868, Monet attempted to kill himself by jumping into the Seine River to drown.  After the birth of his first son, Monet and his family lived in poverty. He was unable to sell his paintings, the French art establishment had rejected him, and his father had cut him off financially."}
        paintingFixed={data.thehousesofparliament.childImageSharp.fixed}
        alt={"The Houses of Parliament, Sunset"}
        rtPanel={{ backgroundColor: "#f49888" }}
        // Mobile
        paintingFluid={data.thehousesofparliamentmobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#493e67" }}
      />

      <Painting
        leftPanel={{ backgroundColor: "#063054" }}
        h1Title={"Water Lilies and Japanese Bridge"}
        sectionDescription={"Later in his life, however, Monet would become one of the most successful artists who ever lived.  He produced a series of water lily paintings like the one pictured here. The lily pond was beside his home in Giverny, France.  Although he had a difficult time selling his paintings when he was young, one of his water lily paintings eventually sold for over $43 million."}
        paintingFixed={data.waterliliesandjapanesebridge.childImageSharp.fixed}
        alt={"Water Lilies and Japanese Bridge"}
        rtPanel={{ backgroundColor: "#b1c2b1" }}
        // Mobile
        paintingFluid={data.waterliliesandjapanesebridgemobile.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#063054" }}
      />

<MonetComponent/>
    </Layout>
  )
}

export default Monet

export const query = graphql`
  {
 impressionsunrise: file(relativePath: { eq: "paintings/monet/impressionsunrise.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     impressionsunrisemobile: file(relativePath: { eq: "paintings/monet/impressionsunrise.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
     caricatureofleonmanchon: file(relativePath: { eq: "paintings/monet/caricatureofleonmanchon.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         caricatureofleonmanchonmobile: file(relativePath: { eq: "paintings/monet/caricatureofleonmanchonmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
     poppyfield: file(relativePath: { eq: "paintings/monet/poppyfield.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         poppyfieldmobile: file(relativePath: { eq: "paintings/monet/poppyfield.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
      wheatstacksendofsummer: file(relativePath: { eq: "paintings/monet/wheatstacksendofsummer.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         wheatstacksendofsummermobile: file(relativePath: { eq: "paintings/monet/wheatstacksendofsummer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }   
          womeninthegarden: file(relativePath: { eq: "paintings/monet/womeninthegarden.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         womeninthegardenmobile: file(relativePath: { eq: "paintings/monet/womeninthegardenmobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
        camilleandachildinthegarden: file(relativePath: { eq: "paintings/monet/camilleandachildinthegarden.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         camilleandachildinthegardenmobile: file(relativePath: { eq: "paintings/monet/camilleandachildinthegarden.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
            sangiorgiomaggioreatdusk: file(relativePath: { eq: "paintings/monet/sangiorgiomaggioreatdusk.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         sangiorgiomaggioreatduskmobile: file(relativePath: { eq: "paintings/monet/sangiorgiomaggioreatdusk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
                thehousesofparliament: file(relativePath: { eq: "paintings/monet/thehousesofparliament.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         thehousesofparliamentmobile: file(relativePath: { eq: "paintings/monet/thehousesofparliament.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
        waterliliesandjapanesebridge: file(relativePath: { eq: "paintings/monet/waterliliesandjapanesebridge.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
         waterliliesandjapanesebridgemobile: file(relativePath: { eq: "paintings/monet/waterliliesandjapanesebridgemobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
